exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GalleryList_image___Pr0B {\n  width: 190px;\n  height: 190px;\n  cursor: pointer;\n}\n\n.GalleryList_image___Pr0B:hover {\n  background-size: 115%;\n}\n\n.GalleryList_container__J9b_7 {\n  max-width: 640px;\n}\n\n.GalleryList_imageContainer__2SlFm {\n  position: relative;\n  overflow: hidden;\n  margin: 10px;\n}\n.GalleryList_label__1-qhe {\n  position: absolute;\n  transform: translateY(100%);\n  left: 0;\n  right: 0;\n  bottom: 0;\n  pointer-events: none;\n  transition: transform 0.25s ease-in-out;\n  background-color: rgba(255, 255, 255, 0.65);\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.GalleryList_imageContainer__2SlFm:hover .GalleryList_label__1-qhe {\n  transform: translateY(0);\n}\n", "", {"version":3,"sources":["C:/Users/Kevin/Documents/Development/RmaWidget/react-widget/src/components/GalleryList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,gBAAgB;CACjB;;AAED;EACE,sBAAsB;CACvB;;AAED;EACE,iBAAiB;CAClB;;AAED;EACE,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;CACd;AACD;EACE,mBAAmB;EACnB,4BAA4B;EAC5B,QAAQ;EACR,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,wCAAwC;EACxC,4CAA4C;EAC5C,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,wBAAwB;CACzB;;AAED;EACE,yBAAyB;CAC1B","file":"GalleryList.module.css","sourcesContent":[".image {\n  width: 190px;\n  height: 190px;\n  cursor: pointer;\n}\n\n.image:hover {\n  background-size: 115%;\n}\n\n.container {\n  max-width: 640px;\n}\n\n.imageContainer {\n  position: relative;\n  overflow: hidden;\n  margin: 10px;\n}\n.label {\n  position: absolute;\n  transform: translateY(100%);\n  left: 0;\n  right: 0;\n  bottom: 0;\n  pointer-events: none;\n  transition: transform 0.25s ease-in-out;\n  background-color: rgba(255, 255, 255, 0.65);\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.imageContainer:hover .label {\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"image": "GalleryList_image___Pr0B",
	"container": "GalleryList_container__J9b_7",
	"imageContainer": "GalleryList_imageContainer__2SlFm",
	"label": "GalleryList_label__1-qhe"
};