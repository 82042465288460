exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Image_main__13u0H {\n  background-size: 100%;\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n  transition: background-size 0.25s ease-in-out;\n}\n", "", {"version":3,"sources":["C:/Users/Kevin/Documents/Development/RmaWidget/react-widget/src/components/Image.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,6BAA6B;EAC7B,6BAA6B;EAC7B,8CAA8C;CAC/C","file":"Image.module.css","sourcesContent":[".main {\n  background-size: 100%;\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n  transition: background-size 0.25s ease-in-out;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"main": "Image_main__13u0H"
};