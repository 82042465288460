exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Gallery_all__2DyWB .Gallery_image__2jitg {\n  width: 80px;\n  height: 80px;\n  cursor: pointer;\n  box-shadow: 1px 1px 2px 0px #00000070;\n}\n\n.Gallery_all__2DyWB .Gallery_image__2jitg:hover {\n  background-size: 115%;\n}\n\n.Gallery_single__2w_y1 .Gallery_image__2jitg {\n  width: 500px;\n  height: 500px;\n  box-shadow: 3px 2px 2px 0px #00000070;\n}\n\n.Gallery_container__2r-nk {\n  display: flex;\n  justify-content: center;\n}\n\n.Gallery_all__2DyWB {\n  width: 110px;\n  height: 500px;\n  justify-content: space-between;\n}\n\n.Gallery_single__2w_y1 {\n  width: 500px;\n  height: 500px;\n}\n", "", {"version":3,"sources":["C:/Users/Kevin/Documents/Development/RmaWidget/react-widget/src/components/Gallery.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sCAAsC;CACvC;;AAED;EACE,sBAAsB;CACvB;;AAED;EACE,aAAa;EACb,cAAc;EACd,sCAAsC;CACvC;;AAED;EACE,cAAc;EACd,wBAAwB;CACzB;;AAED;EACE,aAAa;EACb,cAAc;EACd,+BAA+B;CAChC;;AAED;EACE,aAAa;EACb,cAAc;CACf","file":"Gallery.module.css","sourcesContent":[".all .image {\n  width: 80px;\n  height: 80px;\n  cursor: pointer;\n  box-shadow: 1px 1px 2px 0px #00000070;\n}\n\n.all .image:hover {\n  background-size: 115%;\n}\n\n.single .image {\n  width: 500px;\n  height: 500px;\n  box-shadow: 3px 2px 2px 0px #00000070;\n}\n\n.container {\n  display: flex;\n  justify-content: center;\n}\n\n.all {\n  width: 110px;\n  height: 500px;\n  justify-content: space-between;\n}\n\n.single {\n  width: 500px;\n  height: 500px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"all": "Gallery_all__2DyWB",
	"image": "Gallery_image__2jitg",
	"single": "Gallery_single__2w_y1",
	"container": "Gallery_container__2r-nk"
};